import React from 'react';
import {Page, Container, Button, Wrapper, Grid} from '../../components';

function Front(props) {

  const handleChange = (key, value) => {
    const items = props.data;
    items[key] = value;
    props.setData(
        {...items}
    );
  };

  return (
    <Page name="game">
      <iframe src="./game-files/" className="game"></iframe>
    </Page>
  );
}

export default Front;
