import React from 'react';

import Front from './front';

const Login = (props) => {
  const loginSpotify = () => {
    const scopes = "user-read-recently-played user-modify-playback-state user-read-playback-state playlist-read-private user-follow-modify playlist-modify-public user-read-private user-read-email user-library-modify user-library-read user-top-read"
    const client_id = "387a48a8e3a84e53bac2b7762c1a750f"
    const url_redirect = 'http://localhost:3000'

    window.location.href = `https://accounts.spotify.com/authorize?response_type=token&client_id=${client_id}&scope=${encodeURIComponent(scopes)}&redirect_uri=${url_redirect}`
  }

  const newLogin = () => {
    window.location.href = `https://presaves.sonymusicfans.com/?campaign_id=126294&mailing_list_ids=a0S1p00000SHbGVEA1&retargeting_consent=0&source_channel=Spotify`
  }

  return (
    <Front
      loginWithoutSpotify={() => props.loginWithoutSpotify()}
      callLogin={newLogin}
    />
  )
}

export default Login;
