import React, {useEffect, useState} from 'react';
import {Page, Container, Button, Wrapper, Field, Image, Icon} from '../../components';

import logoImage from '../../assets/images/logo.png';
import twerkImage from '../../assets/images/twerk.png';

function Front(props) {
  const [imagesLoaded, setImagesLoaded] = useState(false);
  useEffect(() => {
    Promise.all(Array.from(document.images).filter(img => !img.complete).map(img => new Promise(resolve => { img.onload = img.onerror = resolve; }))).then(() => {
      setImagesLoaded(true);
    });
  }, [])

  const handleChange = (key, value) => {
    const items = props.data;
    items[key] = value;
    props.setData(
        {...items}
    );
  };

  return (
    <Page
      name="login"
      main={false}
      className={imagesLoaded?'loaded':''}
      footer={
        <> &copy; <script>document.write(new Date().getFullYear())</script> Sony Music Entertainment |
        <a href="http://www.sonymusic.com/privacy/termsandconditions.html" target="_blank" class="sme-privacy-exclude">Terms&nbsp;and&nbsp;Conditions</a>|
        <a href="http://www.sonymusic.com/privacypolicy.html" target="_blank" class="sme-privacy-exclude">Privacy&nbsp;Policy</a>|
        <a href="https://www.sonymusic.com/how-we-use-your-data/" target="_blank" class="sme-privacy-exclude">How We Use Your Data</a>|
        <a target="_blank" href="https://www.sonymusic.com/ccpa-contact-form/" class="sme-privacy-exclude">Do Not Sell My Personal Information</a>|
        <a target="_blank" href="https://www.sonymusic.com/privacy-policy/#your-california-privacy-rights" class="sme-privacy-exclude">Your California Privacy Rights</a>|
        <a href="https://www.sonymusic.com/feedback/" target="_blank" class="sme-privacy-exclude">Send&nbsp;Feedback</a></>
      }
    >
      <Container center>
        <Wrapper>
          <Image className="twerk" src={twerkImage} />
          <Image className="logo" src={logoImage} />
          <div className="pt-2">
            <Button onClick={() => props.callLogin()}><svg className="mr-2" preserveAspectRatio id="Bold" enable-background="new 0 0 24 24" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
              <path
              d="m12 24c6.624 0 12-5.376 12-12s-5.376-12-12-12-12 5.376-12 12 5.376 12 12 12zm4.872-6.344v.001c-.807 0-3.356-2.828-10.52-1.36-.189.049-.436.126-.576.126-.915 0-1.09-1.369-.106-1.578 3.963-.875 8.013-.798 11.467 1.268.824.526.474 1.543-.265 1.543zm1.303-3.173c-.113-.03-.08.069-.597-.203-3.025-1.79-7.533-2.512-11.545-1.423-.232.063-.358.126-.576.126-1.071 0-1.355-1.611-.188-1.94 4.716-1.325 9.775-.552 13.297 1.543.392.232.547.533.547.953-.005.522-.411.944-.938.944zm-13.627-7.485c4.523-1.324 11.368-.906 15.624 1.578 1.091.629.662 2.22-.498 2.22l-.001-.001c-.252 0-.407-.063-.625-.189-3.443-2.056-9.604-2.549-13.59-1.436-.175.048-.393.125-.625.125-.639 0-1.127-.499-1.127-1.142 0-.657.407-1.029.842-1.155z"
              />
            </svg> PLAY WITH SPOTIFY</Button>
          </div>
          <div>
            <p className="mt-1">
              By connecting with Spotify you agree to receive news from Lil Nas X and Columbia.<br/>
              For more information on how we use your data, please see the links below.
            </p>
          </div>
          <div className="pt-3">
            <a onClick={() => props.loginWithoutSpotify()}>I don't have a Spotify account</a>
          </div>
        </Wrapper>
      </Container>
    </Page>
  );
}

export default Front;
