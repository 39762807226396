import React, {useEffect, useState} from 'react';

import './App.scss';

import tokenSpotify from './helpers/tokenSpotify'

import Login from'./pages/Login/index';
import Game from'./pages/Game/index';

function App(props) {
  const [logged, setLogged] = useState(false);

  const without_spotify = localStorage.getItem('without_spotify')

  useEffect(() => {
    if(tokenSpotify() || without_spotify) {
      setLogged(true)
    } else {
      setLogged(false)
    }
    getTokenFromUrl();
  });

  function getTokenFromUrl() {
    var url = window.location.href
    var url_string = url.replace('#', '?');
    var url = new URL(url_string);

    var accessToken = url.searchParams.get("access_token")
    var tokenType = url.searchParams.get("token_type")

    const now = new Date()

    if(accessToken && tokenType) {
      const item = { value: accessToken, expiry: now.getTime() + 3600000 }
      localStorage.setItem('token_spotify', JSON.stringify(item))
      localStorage.removeItem('without_spotify')

      setLogged(true)

      window.location.href='http://localhost:3000'
    }
  }

  const loginWithoutSpotify = () => {
    localStorage.setItem('without_spotify', true)
    setLogged(true)
  }

  return (
    <div className="App">
      { logged ?
        <Game /> :
        <Login loginWithoutSpotify={loginWithoutSpotify} />
      }
    </div>
  );
}

export default App;
