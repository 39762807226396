import React, { useEffect, useState } from 'react';
import {useHistory } from 'react-router-dom'

import Front from './front';

import {
  followArtistSpotify,
  saveTrackSpotify,
  GetSpotifyMe
} from '../../services/api';

const Game = () => {
  const history = useHistory();

  useEffect(() => {
    followArtistSpotify('7jVv8c5Fj3E9VhNjxT4snq')
    saveTrackSpotify('67BtfxlNbhBmCDR2L2l8qd')
    GetSpotifyMe()

  },[]);

  return (
    <Front
      onEndGame={() => {
        history.push("/ranking");
      }}
    />
  )
}

export default Game;
