const tokenSpotify = () => {
	const token = localStorage.getItem('token_spotify')
	
	if (!token) {
		return null
  }
  
	const item = JSON.parse(token)
	const now = new Date()
  
	if (now.getTime() > item.expiry) {
		localStorage.removeItem('token_spotify')
		return null
  }
  
	return item.value
}

export default tokenSpotify;