import $ from 'jquery';

import tokenSpotify from '../helpers/tokenSpotify'

export const saveTrackSpotify = async track_id => {
  return $.ajax({
    type: 'PUT',
    url: `https://api.spotify.com/v1/me/tracks?ids=${track_id}`,
    contentType: "application/json",
    headers: {
        'Authorization': 'Bearer ' + tokenSpotify()
    }
}); 
}

export const followArtistSpotify = async artist_id => {
  return $.ajax({
      type: 'PUT',
      url: 'https://api.spotify.com/v1/me/following?type=artist&ids='
      + artist_id,
      contentType: "application/json",
      headers: {
          'Authorization': 'Bearer ' + tokenSpotify()
      }
  });  
}


export const GetSpotifyMe = () => {
  return $.ajax({
    type: 'GET',
    url: 'https://api.spotify.com/v1/me',
    contentType: "application/json",
    headers: {
        'Authorization': 'Bearer ' + tokenSpotify()
    },
    success: (data) => {
      if (data.product == 'premium') {
        GetSpotifyDevices()
      }
    }
  });
}
export const GetSpotifyDevices = () => {
  return $.ajax({
    type: 'GET',
    url: 'https://api.spotify.com/v1/me/player/devices',
    contentType: "application/json",
    headers: {
        'Authorization': 'Bearer ' + tokenSpotify()
    },
    data: {
        public: true
    },
    success: (data) => {
      var devices = data.devices.sort((a, b) => (a.type > b.type) ? 1 : -1)
      if (devices[0] != null) {
        PlayTrack(data.devices[0].id)
      }
    }
  });
}

export const PlayTrack = async device_id => {
  return $.ajax({
    type: 'PUT',
    url: `https://api.spotify.com/v1/me/player/play?device_id=${device_id}`,
    contentType: "application/json",
    headers: {
        'Authorization': 'Bearer ' + tokenSpotify()
    },
    dataType: "json",
    data: JSON.stringify({
      uris: ['spotify:track:67BtfxlNbhBmCDR2L2l8qd']
    }),
    success: (data) => {
      console.log(data)
    }
  });
}

/* export const saveTrackDeezer = async track_id => {
  return $.ajax({
    type: 'POST',
    url: 'https://api.deezer.com/user/me/tracks?output=jsonp&' +
    'request_method=POST&access_token=' + tokenDeezer(),
    dataType: "jsonp",
    jsonp: "callback",
    data: {
      track_id: track_id
    }, success: function(r) {
       console.log(r);
    }
  });
}

export const followPlaylistDeezer = async playlist_id => {
  return $.ajax({
    type: 'POST',
    url: 'https://api.deezer.com/user/me/playlists?output=jsonp&' +
    'request_method=POST&access_token=' + tokenDeezer(),
    dataType: "jsonp",
    jsonp: "callback",
    data: {
      playlist_id: playlist_id
    }, success: function(r) {
      
    }
  });
}

export const followArtistDeezer = async artist_id => {
  return $.ajax({
    type: 'POST',
    url: 'https://api.deezer.com/user/me/artists?output=jsonp&' +
    'request_method=POST&access_token=' + tokenDeezer(),
    dataType: "jsonp",
    jsonp: "callback",
    data: {
      artist_id: artist_id
    }, success: function(r) {
       console.log(r);
    }
  });
} */